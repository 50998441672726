import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import createSettingsSlice from './slices/settingsSlice';
import ISettings, {
  SearchResult,
  SearchRide,
  PassengersSlice,
  SelectedTicket,
  BookingTimer,
  UserSlice,
  PaymentInfo,
  UpdateBooking,
  ManageTransfers,
  CurrencySlice,
  LocationPicker,
  SearchTransfersData,
  SearchTrackingData,
  RidePlanningSlice,
} from './types/types';
import CreateSearchResultSlice from './slices/searchResultSlice';
import CreateSearchRideSlice from './slices/searchRideSlice';
import CreateSelectedTicketSlice from './slices/selectedTicketSlice';
import CreatePassengersSlice from './slices/passengersSlice';
import CreateBookingTimerSlice from './slices/bookingTimerSlice';
import CreateUserSlice from './slices/userSlice';
import CreatePaymentInfoSlice from './slices/paymentInfoSlice';
import CreateUpdateBookingSlice from './slices/useUpdateBookingSlice';
import CreateManageTransfersSlice from './slices/manageTransfersSlice';
import CreateCurrencySlice from './slices/currencySlice';
import CreateLocationPickerSlice from './slices/locationPickerSlice';
import CreateSearchTransfersDataSlice from './slices/search-transfer-slice';
import CreateSearchTrackingDataSlice from './slices/search-tracking-slice';
import CreateRidePlanningSlice from './slices/ride-planning-slice';

const useStore = create<ISettings>()((...a) => ({
  ...createSettingsSlice(...a),
}));

export const useManageTransfers = create(
  devtools(
    (set, get, api) => ({
      ...CreateManageTransfersSlice(set, get, api),
    }),
    { name: 'useManageTransfers' }
  ) as () => ManageTransfers
);

export const useSearchResult = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateSearchResultSlice(set, get, api),
      }),
      { name: 'useSearchResult' }
    ),
    {
      name: 'searchResult',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => SearchResult
);

export const useSearchRide = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateSearchRideSlice(set, get, api),
      }),
      { name: 'useSearchRide' }
    ),
    {
      name: 'searchRide',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => SearchRide
);

export const useTicketSlice = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateSelectedTicketSlice(set, get, api),
      }),
      { name: 'useTicketSlice' }
    ),
    {
      name: 'selectedTicket',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => SelectedTicket
);

export const usePassengersSlice = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreatePassengersSlice(set, get, api),
      }),
      { name: 'usePassengerSlice' }
    ),
    {
      name: 'passengers',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => PassengersSlice
);

export const useBookingTimerSlice = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateBookingTimerSlice(set, get, api),
      }),
      { name: 'useBookingTimerSlice' }
    ),
    {
      name: 'bookingTimer',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => BookingTimer
);

export const useUserSlice = create(
  devtools(
    (set, get, api) => ({
      ...CreateUserSlice(set, get, api),
    }),
    { name: 'useUserSlice' }
  ) as () => UserSlice
);

export const usePaymentInfoSlice = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreatePaymentInfoSlice(set, get, api),
      }),
      { name: 'usePaymentInfo' }
    ),
    {
      name: 'paymentInfo',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => PaymentInfo
);

export const useUpdateBooking = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateUpdateBookingSlice(set, get, api),
      }),
      { name: 'useUpdateBooking' }
    ) as () => UpdateBooking,
    {
      name: 'updateBooking',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useCurrencySlice = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateCurrencySlice(set, get, api),
      }),
      { name: 'currency' }
    ),
    {
      name: 'currency',
    }
  ) as () => CurrencySlice
);

export const useLocationPicker = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateLocationPickerSlice(set, get, api),
      }),
      { name: 'useLocationPicker' }
    ),
    {
      name: 'locationPicker',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => LocationPicker
);

export const useSearchTransfersData = create(
  persist(
    devtools(
      (set, get, api) => ({
        ...CreateSearchTransfersDataSlice(set, get, api),
      }),
      { name: 'useSearchTransferData' }
    ),
    {
      name: 'searchTransferData',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => SearchTransfersData
);

export const useSearchTrackingData = create(
  devtools(
    (set, get, api) => ({
      ...CreateSearchTrackingDataSlice(set, get, api),
    }),
    { name: 'useSearchTrackingData' }
  ) as () => SearchTrackingData
);

export const useRidePlanningSlice = create(
  devtools(
    (set, get, api) => ({
      ...CreateRidePlanningSlice(set, get, api),
    }),
    {
      name: 'useRidePlanningSlice',
      storage: createJSONStorage(() => sessionStorage),
    }
  ) as () => RidePlanningSlice
);

export default useStore;
