import { StateCreator } from 'zustand';
import { RidePlanningSlice } from '../types/types';
import {
  RidePlanningFilters,
  VirtualVehiclesForRidePlanning,
} from '@/api/repositories/types/ride-planning-types';
import { RidePlanningRideCardProps } from '@/app/[locale]/(admin)/admin/ride-planning/types/ride-planning-ride-card';
import { VirtualVehiclesGroupedByRoute } from '@/app/[locale]/(admin)/admin/ride-planning/types/ride-planning-virtual-vehicles';

const CreateRidePlanningSlice: StateCreator<RidePlanningSlice> = (set) => ({
  virtualVehiclesGroupedByRoute: [] as VirtualVehiclesGroupedByRoute[],
  setVirtualVehiclesGroupedByRoute: (value: VirtualVehiclesGroupedByRoute[]) =>
    set({ virtualVehiclesGroupedByRoute: value }),
  isVirtualVehiclesLoading: false,
  setIsVirtualVehiclesLoading: (value: boolean) => set({ isVirtualVehiclesLoading: value }),
  isVirtualVehiclesError: false,
  setIsVirtualVehiclesError: (value: boolean) => set({ isVirtualVehiclesError: value }),
  rideCards: [{ rideId: 1, transferCards: [] }] as RidePlanningRideCardProps[],
  setRideCards: (value: RidePlanningRideCardProps[]) => set({ rideCards: value }),
  filters: {} as RidePlanningFilters,
  setFilters: (value: RidePlanningFilters) => set({ filters: value }),
  // Edit modal data
  isEditModalOpen: false,
  setIsEditModalOpen: (value: boolean) => set({ isEditModalOpen: value }),
  ridePlanningId: null,
  setRidePlanningId: (value: number | null) => set({ ridePlanningId: value }),
  isEditVehiclesLoading: false,
  setIsEditVehiclesLoading: (value: boolean) => set({ isEditVehiclesLoading: value }),
  isEditVehiclesError: false,
  setIsEditVehiclesError: (value: boolean) => set({ isEditVehiclesError: value }),
  editVehicles: null,
  setEditVehicles: (value: VirtualVehiclesForRidePlanning | null) => set({ editVehicles: value }),
  mapRoutes: null,
  setMapRoutes: (value: string[] | null) => set({ mapRoutes: value }),
  reset: () => set({ rideCards: [] }),
});

export default CreateRidePlanningSlice;
